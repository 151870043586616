@font-face {
	font-family: Stolzl Medium;
	src: url(../fonts/Stolzl-Medium.ttf);
}
@font-face {
	font-family: Stolzl Book;
	src: url(../fonts/Stolzl-Book.ttf);
}
a:focus {
	box-shadow: none !important;
}
button:focus {
	box-shadow: none !important;
}
